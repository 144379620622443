<template>
    <v-app v-cloak>
        <v-container class="my-5">
            <v-card-text v-if="errorUIMessage" class="mt-10">
                <h3> {{errorUIMessage}} </h3>
            </v-card-text>

            <div v-if="!errorUIMessage" class="custom-debugx">
                <v-row class="mt-10">
                    <v-col class="col-8 offset-2" align="center">
                        <h1 @click="editList()" class="custom-debugx">
                            <ListIcon v-if="listIconSlug" :listIconSlug="listIconSlug" class="mr-2 d-none d-md-flex" size="large" />
                            <ListIcon v-if="listIconSlug" :listIconSlug="listIconSlug" class="mr-2 d-flex d-md-none" size="small" />
                            {{topic}} 
                        </h1>
                    </v-col>
                    <v-col class="col-2" style="position:relative; display:flex; justify-content: right; align-items:flex-start;">
                        <TagViewer v-if="viewingListId" class="d-none d-lg-flex" style="position: absolute; right:0px" :listId="viewingListId" orientation="vertical" />
                    </v-col>
                </v-row>

                <v-row class="mt-1" v-if="!isViewingSelf()">
                    <v-col class="col-12" align="center">
                        <ChimeInButton :viewingListId="0" :viewingListName="topic"/>
                    </v-col>
                </v-row>

                <div class="row py-0 mt-0" style="margin-bottom: 100px; display:flex; justify-content: center;">
                    <!-- <div v-if="isViewingSelf()" class="col col-10 offset-2 py-0 mt-2 mb-0 ">
                        <ListFilters :toggleUnRanked="() => showUnRanked = !showUnRanked" />
                    </div> -->
                    <div class="col" style="max-width: 800px;">
                        <div v-for="(item, index) in items" :key="item.listItemId" class="col col-12" style="max-width: 800px;">
                            <ListItem
                            :id = "`list-item-${index+1}`"
                            :index="index" 
                            :item="item"
                            listFormat="Media"
                            :isRanked="true"
                            />
        <!-- <v-row align="center">
            <v-col class="col-12" style="h3">
                <ListItemPlainText v-if="item.list_format=='Plain Text'" ref="itemEditor" editMode="read" :item="item" :index="index" />

                <ListItemMedia v-if="item.list_format=='Media'" ref="itemEditor" editMode="read" :item="item" :index="index" />

                <ListItemRichText v-if="item.list_format=='Rich Text'" ref="itemEditor" editMode="read" :item="item" :index="index" />
            </v-col>
        </v-row> -->

                        </div>
                    </div>
                </div>


                <!-- <v-row class="py-0 mt-0" style="margin-bottom: 100px">
                    <v-col class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <ListItems v-if="viewingListId" ref="listItemsRef" :listFormat="listFormat" :viewingListId="viewingListId"/>
                    </v-col>
                </v-row> -->
            </div>
        </v-container>
    </v-app>
</template>
<script>
import api from '/src/utils/api';
import auth from "/src/utils/auth";

export default {
    components: {
        // AddFooter: () => import("/src/views/list/components/AddFooter.vue"),
        ChimeInButton: () => import("/src/views/list/components/ChimeInButton.vue"),
        // ListFilters: () => import("./components/ListFilters"),
        // ListItems: () => import("/src/views/list/components/ListItems.vue"),
        // ListTitle: () => import("/src/views/list/components/ListTitle.vue"),
        // ListSettingsModal: () => import("/src/components/ListSettingsModal"),
        ListItemPlainText: () => import("/src/views/list/components/ListItemPlainText.vue"),
        ListItemMedia: () => import("/src/views/list/components/ListItemMedia.vue"),
        ListItemRichText: () => import("/src/views/list/components/ListItemRichText.vue"),
        ListItem: () => import("/src/views/list/components/ListItem.vue"),
        TagViewer: () => import("/src/components/TagViewer.vue"),
    },
    data() {
        return {
            channelId: null,
            channelName: '',
            canWrite: null,
            errorUIMessage: null,
            errorInvalidList: false,
            errorCouldNotRetrieve: false,
            isDragging: false,
            isViewingSelf: auth.isViewingSelf,
            items: [],
            listFormat: '',
            listIconSlug: null,
            topic: '',
            newIndex: null,
            oldIndex: null,
            showUnRanked: false,
            viewingListId: null,
        };
    },
    metaInfo () {
        return {
            title: "ChimeChannel " + this.topic,
        };
    },
    async mounted() {
        // this.channelName = this.$route.params.channel;
        sessionStorage.setItem('viewingChannelId', null);
        this.topic = this.$route.params.topic;
        console.log(" fetching this.topic",this.topic);

        let url = `get-list-tally?topic=${this.topic}`
        api.getCall(url).then(lists => {
            console.log("3. retrieved this.listName",lists);
            this.items = lists;
            // console.log("3. retrieved this.listName",this.listName);
            // If the list is found, load it. If not found, we create it if the user is the channel owner.
            // if (lists[0])
            //     this.loadList(lists[0]);
            // else
            //     this.errorUIMessage = 'This list either does not exist or is not shared.';
        });
    },
    methods: {
        loadList(listRecord) {
            // console.log("listRecord", listRecord);
            this.listIconSlug = listRecord.list_icon_slug;
            // console.log("this.listIconSlug", this.listIconSlug);
            // this.listFormat = listRecord.list_format_name;
            // this.viewingListId = listRecord.list_id
            // this.canWrite = listRecord.logged_in_user_can_write;
            // console.log("canWrite", this.canWrite, listRecord.friends_write, listRecord.public_write, auth.isViewingSelf());
            // localStorage.setItem('viewingListId', this.viewingListId);
            // localStorage.setItem('viewingListName', listRecord.list_name);
        },
    },
};
</script>

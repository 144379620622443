import Vue from "vue";
import VueRouter from "vue-router";
import Callback from "./views/Callback.vue";
import Channel from "./views/channel/Channel.vue";
import Home from "./views/home/Home.vue";
import Friends from "./views/friends/Friends.vue";
import List from "./views/list/List.vue";
import Public from "./views/public/Public.vue";
import PublicQR from "./views/public/PublicQR.vue";
import Profile from "./views/profile/Profile.vue";
import Tally from "./views/tally/Tally.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "/sys/callback",
        component: Callback,
    },
    {
        path: "/sys/profile",
        component: Profile,
    },
    {
        path: "/sys/friends",
        component: Friends,
    },
    {
        path: "/sys/public-qr",
        name: "PublicQR",
        component: PublicQR,
        meta: { showHeader: false },
    },
    {
        path: "/sys/tally/:topic",
        component: Tally,
    },
    {
        path: "/public",
        component: Public,
    },
    {
        path: "/:channel",
        name: "Channel",
        component: Channel
    },
    {
        path: "/:channel/:list",
        name: "List",
        component: List
    },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;


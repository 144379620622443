<template>
    <div class="root">
        <v-app>
            <div class="channel-header" style="">
                <div class="col col-12" style="display:flex; justify-content: center; align-items: center;">
                    <div class="d-none d-sm-block" style="width:150px;">
                        <div class="row">
                            <div class="col col-6 py-1 label" style=""> Followers: </div>
                            <div class="col col-6 py-1 value"> {{follow_count}} </div>
                        </div>
                        <div class="row">
                            <div class="col col-6 py-1 label" style=""> Views: </div>
                            <div class="col col-6 py-1 value"> {{view_count}} </div>
                        </div>
                        <div class="row">
                            <div class="col col-6 py-1 label"> Lists: </div>
                            <div class="col col-6 py-1 value"> {{listsCount}} </div>
                        </div>
                    </div>
                    
                    <div class="" style="display:flex; justify-content: center; align-items: center;">
                        <div class="channel-avatar" style="" >
                            <img v-if="avatarUrl" :src="avatarUrl" style="height:100px; width:100px; border-radius: 100px; border: 2px solid white;" />
                            <CustomIcon v-if="!avatarUrl" :size="70" color="green" glyph="mdi-account" />
                        </div>
                        <h1>
                            {{$route.params.channel}}
                            <span v-if="(!isViewingSelf() && isLoggedIn)">
                                <a v-if="!isFollowing" @click="follow(channelName)" style="font-size:small"> follow </a>
                                <a v-if="isFollowing" @click="unfollow(channelName)" style="font-size:small"> unfollow </a>
                                
                                <ListenBell :channelName="channelName" />
                            </span>
                        </h1>
                    </div>
                    <div class="d-none d-sm-block" style="width:150px;">
                    </div>
                </div>
            </div>
            <v-container class="my-2">
                <v-row style="margin-bottom: -12px">
                    <v-col class="col-12">
                        <NewListModal v-if="isViewingSelf()" :channelId=channelId :channelName=channelName :handleSuccess=refreshList :existingLists=lists />
                    </v-col>
                </v-row>
                <v-row style="height:100px;" class="">
                    <div v-if="isViewingSelf()" class="col col-10 offset-2 col-lg-2 offset-lg-0 py-0 mt-2 mb-0">
                        <ChannelFilters :filteredListsCount="filteredListsCount"
                            :toggleFriendAccess="() => showFriendAccess = !showFriendAccess"
                            :togglePrivate="() => showPrivate = !showPrivate"
                            :togglePublicAccess="() => showPublicAccess = !showPublicAccess"
                        />
                    </div>
                    <v-col class="col-12 col-sm-10 offset-sm-1 col-lg-8 col-xl-6 offset-xl-3" style="margin: auto">
                        <ListPanel v-for="(l, index) in filteredLists" :index="index" :key="index" :list="l" :advancedMode="true" :refreshList=refreshList />
                    </v-col>
                    <div v-if="isViewingSelf()" class="col col-12 col-lg-2">
                    </div>
                </v-row>
            </v-container>
        </v-app>
    </div>  
</template>
<script>
import api from '/src/utils/api';
import auth from "/src/utils/auth";

export default {
    name: 'Channel',
    components: {
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
        ListenBell: () => import("/src/components/ListenBell"),
        ListPanel: () => import("/src/components/ListPanel"),
        ChannelFilters: () => import("./components/ChannelFilters"),
        NewListModal: () => import("./components/NewListModal"),
    },
    data() {
        return {
            avatarUrl: null,
            channelId: null,
            channelName: "temp",
            isFollowing: false,
            isLoggedIn: auth.isLoggedIn,
            isViewingSelf: auth.isViewingSelf,
            lists: [],
            showFriendAccess: true,
            showPrivate: true,
            showPublicAccess: true,
            view_count: 0,
            follow_count: 0,
        };
    },
    computed: {
        listsCount() {
            return this.lists?.length; 
        },
        filteredListsCount() {
            return this.filteredLists?.length; 
        },
        filteredLists() {
            return this.lists?.filter(l => {
                if (this.showPublicAccess && (l.public_read == 1 || l.public_edit == 1 || l.public_append == 1))
                    return true;
                else if (this.showFriendAccess && (l.friends_read == 1 || l.friends_edit == 1 || l.friends_append == 1 || l.public_read == 1 || l.public_edit == 1 || l.public_append == 1))
                    return true;
                else if (this.showPrivate && (l.friends_read == 0 && l.friends_edit == 0 && l.friends_append == 0 && l.public_read == 0 && l.public_edit == 0 && l.public_append == 0))
                    return true;
                else
                    return false;
            })
        }
    },
    metaInfo () {
        return {
            title: "ChimeChannel " + this.channelName,
        };
    },
    mounted() {
        this.channelName = this.$route.params.channel;
        this.refreshChannel();
        this.refreshList();
    },
    methods: {
        refreshChannel() {
            api.getCall(`get-channels?channel=${this.channelName}`).then(channels => {
                this.avatarUrl = channels[0].avatar_large_url;//localStorage.getItem('avatarLargeUrl');
                this.channelId = channels[0].channel_id;
                sessionStorage.setItem('viewingChannelId', this.channelId);
                this.isFollowing = channels[0].is_following;
                this.view_count = channels[0].view_count;
                this.follow_count = channels[0].follow_count;
            });
        },
        refreshList(scrollToBottom) {
            this.lists = null;
            let url = `get-lists?channel=${this.channelName}`;
            api.getCall(url).then(lists => {
                this.lists = lists;

                // Sometimes, like when cloning a list, we want to focus on the newly created list.
                if( scrollToBottom == true ) {
                    this.$nextTick(() => {
                        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                    });
                }
            });
        },
        follow(channelName) {
            api.postAuthRequired('follow-channel', {channelName}).then(() => {
                this.refreshChannel();
            });
        },
        unfollow(channelName) {
            api.postAuthRequired('unfollow-channel', {channelName}).then(() => {
                this.refreshChannel();
            });
        },
    },
};
</script>
<style scoped>
.channel-header {
    background-color: #eeeeee;
    /* background-color: blue; */
    margin-top:48px;
    padding-top:12px;
    display:flex;
    flex-direction: row;

}

.channel-avatar {
    background-color: white;
    border: 2px solid green;
    border-radius: 100px;
    display:flex;
    justify-content: center;
    align-items:center;
    height:100px;
    width:100px;
    margin-right:12px;
}
.label {
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
}
.value {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
}
</style>
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueSafeHTML, { allowedTags } from 'vue-safe-html';

Vue.use(VueSafeHTML, {
  allowedTags: [...allowedTags, 'marquee', 'blockquote','span','div','p','img'],
});

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        anchor: '#4CAF50',
        background: '#0000ff',
      },
    },
  },

});
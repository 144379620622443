<template>
    <div class="root">
        <v-app>
            <v-container class="my-10">
                <div class="row mt-10">
                    <div class="col col-12 col-md-3">
                        <PictureUpload/>
                    </div>
                    <div class="col col-10 offset-1 col-md-6" align="center">
                        <v-card class="mx-auto">
                            <v-form @submit="setChannelName()" v-model="isValid" v-on:submit.prevent>
                                <v-card-text>
                                    <v-text-field 
                                    v-model="channelName" 
                                    label="Channel Name" 
                                    @keydown="showNameChangeSuccess=false"
                                    :rules="alphaNumeric"
                                    required/>
                                </v-card-text>

                                <v-card-actions class="justify-center">
                                    <v-btn type="submit" :disabled="!isValid" color="green white--text" > SAVE </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                        
                        <div v-if="showNameChangeSuccess" class="mt-10">
                            <FadeAlert ref="nameSavedAlert" />
                            <h1> <a :href="domain + '/' +  channelName">{{domain}}/{{channelName}}</a> </h1>
                        </div>
                    </div>
                    <div class="col col-3" />
                </div>
            </v-container>
        </v-app>
    </div>  
</template>
<script>
    import api from '/src/utils/api';

    export default {
        components: {
            PictureUpload: () => import("/src/views/profile/components/PictureUpload.vue"),
            FadeAlert: () => import("/src/components/FadeAlert"),
        },
        data() {
            return {
                channelId: null,
                channelName: null,
                showNameChangeSuccess: false,
                domain: window.location.origin,
                isValid:false,
                alphaNumeric: [
                    str => !!str || 'Name is required',
                    str => /^[A-Za-z\d]*$/.test(str) || 'Only letters and numbers are allowed.',
                ],
            };
        },
        mounted() {
            api.getUser().then((user) => {
                this.channelId = user[0].channelId;
                this.channelName = user[0].channelName;
            });
        },
        methods: {
            setChannelName() {
                api.setChannelName(this.channelId, this.channelName).then((res) => {
                    this.channelName = res[0].channelName;
                    this.showNameChangeSuccess = true;
                    this.$nextTick(() => {
                        this.$refs.nameSavedAlert.showAlert('Name Saved!');
                    });
                });
            },
        },
    };
</script>

<template>
    <div class="root">
        <v-app>
            <div class="mt-10" />
            <v-container class="my-1">
                <v-row class="mt-1">
                    <v-col class="col-xs-12 col-md-8 offset-md-2">
                        <div v-if="isLoggedIn()">
                            <h3 class="mb-2"> <a :href="domain + '/' +  channelName"> Go to my lists > </a> </h3>
                        </div>
                        <TrendingTopicsPanels />
                        <RecentListPanels/>
                    </v-col>
                </v-row>
            </v-container>
        </v-app>
    </div>
</template>
<script>
import auth from "/src/utils/auth";

const Home = {
    name: 'Home',
    components: {
        RecentListPanels: () => import("./components/RecentListPanels"),
        TrendingTopicsPanels: () => import("./components/TrendingTopicsPanels"),
    },
    data() {
        return {
            channel: '',
            channelName: null,
            domain: window.location.origin,
            items: [],
            isLoggedIn: auth.isLoggedIn,
            lists: '',
        };
    },
    computed: {
    },
    mounted() {
        this.channelName = localStorage.getItem('primaryChannelName');
    },
    methods: {
    },
};

export default Home;

</script>
<style scoped>
th {
   font-weight: bold;
}
td { 
  padding-right: 20px;
}
</style>
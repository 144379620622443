<template>
    <div class="root">
        <v-app>
            <v-container class="my-10">
                <div class="row mt-10">
                    <div class="col col-3" />
                    <div class="col col-12">
                        <h3>
                            The "public" channel is for public use.
                        </h3>
                        <h3>
                        Anything created here can be viewed and edited anonymously by anyone.
                        </h3>
                        <div class="mt-10" style="display:flex; justify-content: center;">
                            <a :href="'/public/random'" style="">
                                <v-btn color="green white--text" v-bind="attrs" v-on="on" >
                                    Create Public List
                                </v-btn>
                            </a>
                        </div>
                    </div>
                    <div class="col col-3" />
                </div>
            </v-container>
        </v-app>
    </div>  
</template>
<script>
    export default {
        components: {
        },
        mounted() {
        },
        methods: {
        },
    };
</script>

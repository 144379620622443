import axios from "axios";
import {getAccessToken, getIdToken} from "@/utils/auth";
import Vue from "vue";


const BASE_URL = window.location.protocol + "//" + window.location.hostname + ":8000";

function postAuthRequired(route, data) {
    // console.log("postAuthRequired::route", route);
    // console.log("postAuthRequired::data", data);
    let url = `${BASE_URL}/api/${route}`;
    let body = { "data": data };
    let headers = { "headers": { Authorization: `Bearer ${getAccessToken()}` } };

    return axios.post(url, body, headers)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Vue.toasted.global.api_error();
            console.error('Error fetching data:', error.message);
            throw error.message;
        });
}

function postAuthOptional(route, data) {
    // console.log("postAuthRequired::route", route);
    // console.log("postAuthRequired::data", data);
    let url = `${BASE_URL}/api/${route}`;
    let body = { "data": data };
    let headers = {};
    if (getAccessToken())
        headers = { "headers": { Authorization: `Bearer ${getAccessToken()}` } };

    return axios.post(url, body, headers)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Vue.toasted.global.api_error();
            console.error('Error fetching data:', error.message);
            throw error.message;
        });
}

function getCall(route, data) {
    // console.log("getCall::route", route);
    // console.log("getCall::data", data);
    let url = `${BASE_URL}/api/${route}`;
    // // TODO: Do we ever use body data on Get?
    // let body = {"data": data};
    let headers = {};
    if (getAccessToken()) {
        headers = {
            "headers": { Authorization: `Bearer ${getAccessToken()}` }
        };
    }
    // console.log("getCall::Vue", Vue);

    return axios.get(url, headers)
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        Vue.toasted.global.api_error();
        console.error('Error fetching data:', error.message);
        throw error.message;
    });
}

function getTags(listId) {
    let url = `get-tags?1=1`;
    if (listId)
        url += `&listId=${listId}`;

    return getCall(url).then((response) => {
        let tagArray = response.map( t=> {
            return { name: t.tag_name, code: t.tag_name };
        });

        return tagArray;
    });
}

function updateTags(listId, tagArray) {
    // console.log("updateTags::tagArray", tagArray);
    let tags = tagArray.map(t => t.name).join(",");
    // console.log("updateTags::tags", tags);

    return postAuthRequired('update-tags', {listId, tags});
}

function getUser() {
    const url = `${BASE_URL}/api/get-user`;
    return axios.post( url
        ,{data: {idToken: `${getIdToken()}`} }
        ,{headers: { Authorization: `Bearer ${getAccessToken()}` }
    }
    ).then((response) => {
        localStorage.setItem('primaryChannelId', response.data[0]?.channelId);
        localStorage.setItem('primaryChannelName', response.data[0]?.channelName);
        return response.data;
    });
}

function logVisit() {
    const url = `${BASE_URL}/api/log-visit`;
    this.channelName = localStorage.getItem('primaryChannelName');

    let headers = {};
    if (getAccessToken()) {
        headers = {
            "headers": { Authorization: `Bearer ${getAccessToken()}` }
        };
    }

    return axios.post( url ,{data: {} } ,headers )
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        Vue.toasted.global.api_error();
        console.error('Error fetching data:', error.message);
    });
}

// Get user data from the identity provider, such as Facebook and Google.
function syncIDPUser() {
    const url = `${BASE_URL}/api/sync-idp-user`;
    return axios.post( url
        ,{data: {idToken: `${getIdToken()}`} }
        ,{headers: { Authorization: `Bearer ${getAccessToken()}` }
    }
    ).then((response) => {
        console.log("syncIDPUser::response.data", response.data);
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("avatarUrl", response.data.picture);
        localStorage.setItem("avatarLargeUrl", response.data.picture_large);
        return response.data;
    })
    .catch((error) => {
        Vue.toasted.global.api_error();
        console.error('Error fetching data:', error.message);
    });
}

function setChannelName(channelId, channelName) {
    const url = `${BASE_URL}/api/set-channel-name`;
    return axios.post( url
        ,{data: {channelId:channelId, channelName:channelName}}
        ,{headers: { Authorization: `Bearer ${getAccessToken()}` }
    }
    )
    .then((response) => {
        const newChannelName = response.data[0]?.channelName;
        localStorage.setItem('primaryChannelName', newChannelName);
        document.dispatchEvent(new CustomEvent("channel-name-change", { "detail": newChannelName }));
        return response.data;
    })
    .catch((error) => {
        Vue.toasted.global.api_error();
        console.error('Error fetching data:', error.message);
    });
}

function setAvatar (file) {
    var url = `${BASE_URL}/api/avatar`;

    const formData = new FormData();
    formData.append('avatar', file);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${getAccessToken()}`
        }
    };
    return axios.post(url, formData, config)
    .then((response) => {
        localStorage.setItem("avatarUrl", response.data.picture);
        document.dispatchEvent(new CustomEvent("avatar-change", { "detail": response.data.picture }));
        return response.data;
    })
    .catch((error) => {
        Vue.toasted.global.api_error();
        console.error('Error fetching data:', error.message);
    });
}

function uploadIcon (file) {
    // console.log("uploadIcon");
    var url = `${BASE_URL}/api/list-icon`;

    const formData = new FormData();
    formData.append('list-icon', file);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${getAccessToken()}`
        }
    };
    return axios.post(url, formData, config)
    .then((response) => {
        // console.log("uploadIcon api called");
        return response.data;
    })
    .catch((error) => {
        Vue.toasted.global.api_error();
        console.error('Error fetching data:', error.message);
    });
}

export default {
    getCall,
    getTags,
    getUser,
    logVisit,
    postAuthOptional,
    postAuthRequired,
    setAvatar,
    setChannelName,
    syncIDPUser,
    uploadIcon,
    updateTags,
}
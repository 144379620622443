import decode from "jwt-decode";
import auth0 from "auth0-js";
import api from '/src/utils/api';


const USER_KEY = "auth_user_object";
const CLIENT_ID = "c1BBAO8UhoNPF3fv5ddzUnqJmdHlK9Id";
const CLIENT_DOMAIN = "dev-8f-141ei.us.auth0.com";
const LOGOUT_REDIRECT = window.location.origin;
const SCOPE = "full_access"; //'openid email profile offline_access'
const AUDIENCE = "ChimeChannelAPI";

const auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: CLIENT_DOMAIN
});

export function login() {
  var callbackURL = window.location.origin + "/sys/callback";
  var previousRoute = new URL(window.location.href).pathname
  auth.authorize({
    responseType: "token id_token",
    redirectUri: callbackURL + "?previousRoute=" + previousRoute,
    audience: AUDIENCE,
    scope: SCOPE,
    // prompt: "consent"
    // responseMode: "query",
  });
}

export function logout() {
    console.log("logout");
    let deviceToken = localStorage.getItem('deviceToken');
    // unregister any logged out user with this deviceToken.
    api.postAuthRequired('unregister-device', {deviceToken});


    localStorage.clear();

    // Restore the one item we want to keep.
    localStorage.setItem('deviceToken', deviceToken);
    console.log("logout::deviceToken", deviceToken);


  // localStorage.removeItem("id_token");
  // localStorage.removeItem("access_token");
  // localStorage.removeItem('primaryChannelId');
  // localStorage.removeItem('primaryChannelName');
  // localStorage.removeItem('avatarUrl');


    auth.logout({
        returnTo: LOGOUT_REDIRECT,
        client_id: CLIENT_ID
    });
}

// export function getAccessToken() {
//     const accessToken = localStorage.getItem("access_token");
//     // Expired tokens will be rejected by the backend. It's better to go in as anonymous.
//     if (!accessToken || isTokenExpired(accessToken))
//       return null;
//     else
//       return accessToken;
// }
  
// export function getIdToken() {
//     return localStorage.getItem("id_token");
// }
  
export function getIdToken() {
    let idToken = localStorage.getItem("id_token")
    // Check if token is malformed.
    try {
        decode(idToken);
    }
    catch {
        idToken = null;
    }    

    return idToken;
}
    

export function getAccessToken() {
  let accessToken = localStorage.getItem("access_token");

    // Check if token is malformed.
    try {
        decode(accessToken);
    }
    catch {
        accessToken = null;
    }

  // Expired tokens will be rejected by the backend. It's better to go in as anonymous.
  if (!accessToken || isTokenExpired(accessToken))
    accessToken = null;
   
    return accessToken;
}

// export function getIdToken() {
//     let idToken = localStorage.getItem("id_token")
//     // Check if token is malformed.
//     try {
//         decode(idToken);
//     }
//     catch {
//         idToken = null;
//     }

//     return idToken;
// }



// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
  // console.log("getParameterByName::name", name);
  // console.log("window.location.hash", window.location.hash);
  const match = RegExp("[#&]" + name + "=([^&]*)").exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

// Get and store access_token in local storage
export async function setAccessToken() {
  // console.log("setAccessToken1::accessToken",accessToken);
  const accessToken = getParameterByName("access_token");
  console.log("hi setAccessToken::accessToken",accessToken);
  localStorage.setItem("access_token", accessToken);
}

// Get and store id_token in local storage
export function setIdToken() {
  const idToken = getParameterByName("id_token");
  console.log("hi setIdToken::idToken",idToken);
  localStorage.setItem("id_token", idToken);
}

export function setUser() {
  // auth.parseHash({ hash: window.location.hash }, function(err, authResult) {
  //   if (err) {
  //     return console.log(err);
  //   }
  // const accessToken = localStorage.getItem("access_token"); //authResult.accessToken
  //   auth.client.userInfo(accessToken, function(err, user) {
  //     console.log("hi setUser::user", user);
  //     localStorage.setItem(USER_KEY, user);
  //   });
  // });  
}

export function isLoggedIn() {
  const accessToken = getAccessToken();
//   console.log("isLoggedIn::accessToken",accessToken);
//   console.log("isLoggedIn::!!accessToken",!!accessToken);
  return !!accessToken && !isTokenExpired(accessToken);
}

export function isViewingSelf() {
  if (!isLoggedIn())
    return false;

  let primaryChannelId = localStorage.getItem('primaryChannelId');
  let viewingChannelId = sessionStorage.getItem('viewingChannelId');
//   console.log("isViewingSelf::primaryChannelId",primaryChannelId);
//   console.log("isViewingSelf::viewingChannelId",viewingChannelId);
//   console.log("isViewingSelf::equals",primaryChannelId != viewingChannelId);

  if (primaryChannelId != viewingChannelId)
    return false;
  else
    return true;
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

export function isTokenExpired(token) {
  try {
      const expirationDate = getTokenExpirationDate(token);
      // console.log("token expirationDate", expirationDate);
      return expirationDate < new Date();
  }
  catch {
    return true;
  }
}

export default {
    isLoggedIn,
    isTokenExpired,
    isViewingSelf,
    login,
    logout,
    getIdToken,
    getAccessToken,
    setAccessToken,
    setIdToken,
}
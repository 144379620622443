<template>
    <div v-if="loading===false">
        <v-app v-cloak>
            <v-container class="my-5">
                <v-card-text v-if="errorUIMessage" class="mt-10">
                    <h3> {{errorUIMessage}} </h3>
                </v-card-text>

                <div v-if="!errorUIMessage" class="">
                    <v-row class="mt-10">
                        <v-col v-if="!publicMode" class="col-8 offset-2" align="center">
                            <ListTitle :channelName="channelName" :listId="viewingListId" :listName="listName" :listIconSlug="listIconSlug" :list="list" />
                        </v-col>
                        <v-col v-if="!publicMode" class="col-2" style="position:relative; display:flex; justify-content: right; align-items:flex-start;">
                            <TagViewer v-if="viewingListId" class="d-none d-lg-flex" style="position: absolute; right:0px" :listId="viewingListId" orientation="vertical" />
                        </v-col>
                    </v-row>

                    <v-row class="mt-1" v-if="!isViewingSelf() && !publicMode">
                        <v-col class="col-12" align="center">
                            <ChimeInButton :viewingListId="list.list_id" :viewingListName="list.list_name"/>
                        </v-col>
                    </v-row>

                    <v-row class="py-0 mt-0" style="margin-bottom: 100px">
                        <div class="col col-10 offset-2 py-0 mt-2 mb-0 custom-debugx">
                            <ListOptions v-if="list" ref="listOptionsRef" :toggleUnRanked="() => showUnRanked = !showUnRanked" :listName="listName" :list="list" :publicMode="publicMode"/>
                            <!-- <div v-if="isViewingSelf()" class="col col-10 offset-2 col-lg-2 offset-lg-0 py-0 mt-2 mb-0">
                                <ChannelFilters :filteredListsCount="filteredListsCount" />
                            </div> -->
                        </div>
                        <v-col class="col-12" style="margin: auto">
                            <ListItems v-if="viewingListId" ref="listItemsRef" :listFormat="listFormat" :publicMode="publicMode" :viewingListId="viewingListId" :showUnRanked="showUnRanked" :showUnrankToggle="showUnrankToggle"/>
                        </v-col>
                    </v-row>


                    <!-- <v-row class="py-0 mt-0" style="margin-bottom: 100px">
                        <v-col class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <ListItems v-if="viewingListId" ref="listItemsRef" :listFormat="listFormat" :viewingListId="viewingListId"/>
                        </v-col>
                    </v-row> -->
                </div>
            </v-container>
            <AddFooter v-if="canAppend" :addToList=addToList />
        </v-app>
    </div>  
</template>
<script>
import api from '/src/utils/api';
import auth from "/src/utils/auth";

const List = {
    name: 'List',
    components: {
        AddFooter: () => import("/src/views/list/components/AddFooter.vue"),
        ChimeInButton: () => import("/src/views/list/components/ChimeInButton.vue"),
        ListOptions: () => import("./components/ListOptions"),
        ListItems: () => import("/src/views/list/components/ListItems.vue"),
        ListTitle: () => import("/src/views/list/components/ListTitle.vue"),
        ListSettingsModal: () => import("/src/components/ListSettingsModal"),
        TagViewer: () => import("/src/components/TagViewer.vue"),
    },
    data() {
        return {
            channelId: null,
            channelName: '',
            canAppend: null,
            errorUIMessage: null,
            errorInvalidList: false,
            errorCouldNotRetrieve: false,
            isDragging: false,
            isViewingSelf: auth.isViewingSelf,
            list:null,
            listFormat: '',
            listIconSlug: null,
            listName: '',
            loading: true,
            newIndex: null,
            oldIndex: null,
            publicMode: false,
            showUnRanked: false,
            viewingListId: null,
        };
    },
    metaInfo () {
        return {
            title: "ChimeChannel " + this.listName,
        };
    },
    async mounted() {
        this.channelName = this.$route.params.channel;
        this.listName = this.$route.params.list;
        this.editListName = this.listName;
        if (this.channelName == 'public')
            this.publicMode = true;

        // console.log("1. this.channelName",this.channelName);
        // console.log("1. this.listName",this.listName);
        // A list name should consists of only numbers, letters, hyphens and spaces.
        if (/^[A-Za-z\d\s-']*$/.test(this.listName) == false) {
            // console.log("1.ERROR this.listName",this.listName);
            // this.errorInvalidList = true;
            this.errorUIMessage = 'A list name should consists of only numbers, letters, hyphens and spaces.';
            return;
        }

        // "random" is a special keyword in the url which will create a new list with a random name.
        if (this.listName === 'random') {
            const uuid = crypto.randomUUID();
            window.location.href = "/" + this.channelName + "/temp-" + uuid + "?listNameEditMode=write";
            return;
        }

        api.getCall(`get-channels?channel=${this.channelName}`).then(channels => {
            this.channelId = channels[0].channel_id;
            sessionStorage.setItem('viewingChannelId', this.channelId);

            // console.log("2. fetching this.listName",this.listName);

            let url = `get-list?channel=${this.channelName}&list=${this.listName}`
            api.getCall(url).then(lists => {
                // console.log("3. retrieved this.listName",this.listName);
                // If the list is found, load it. If not found, we create it if the user is the channel owner.
                if (lists[0])
                    this.loadList(lists[0]);
                else if (auth.isViewingSelf() || this.publicMode)
                    this.createList(this.listName);
                else
                    this.errorUIMessage = 'This list either does not exist or is not shared.';
            });
        });

        // We're not necessarily waiting for the api calls to complete.
        // This loading variable is to prevent any flash rendering before possibly executing a url redirect.
        this.loading = false;
    },
    methods: {
        addToList(newItem) {
            api.postAuthOptional('add-list-item', {listId:this.viewingListId, newItem, isViewingSelf: auth.isViewingSelf()}).then(() => {
                // this.refreshList();
                this.$refs.listItemsRef.refreshList(this.$refs.listItemsRef.items.length);
            });
        },
        createList(newListName) {
            // console.log("4.b. create", newListName);
            api.postAuthOptional('create-list', {channelId:this.channelId, listName:newListName}).then(lists => {
                this.loadList(lists[0]);
            });
        },
        loadList(listRecord) {
            console.log("listRecord", listRecord);
            this.list = listRecord;
            this.listIconSlug = listRecord.list_icon_slug;
            // console.log("listRecord.public_participation_count", listRecord.public_participation_count);
            this.listFormat = listRecord.list_format_name;
            this.viewingListId = listRecord.list_id
            this.canAppend = this.publicMode || listRecord.logged_in_user_can_append;
            // console.log("canWrite", this.canWrite, listRecord.friends_write, listRecord.public_write, auth.isViewingSelf());
            localStorage.setItem('viewingListId', this.viewingListId);
            localStorage.setItem('viewingListName', listRecord.list_name);
        },
        refreshBrowser(newListName) {
            window.location.href = "/" + this.channelName + "/" + newListName;
        },
        showUnrankToggle() {
            this.$refs.listOptionsRef.showUnrankToggle();
        },
    },
};

export default List;

</script>

<template>
    <div class="root">
        <v-app>
            <div v-for="(r,row_index) in rows" :key="row_index" :style="{marginTop:`${yMargin}px`}" style="display:flex;">
                <div v-for="(c,col_index) in cols" :key="col_index" :style="{marginLeft:`${xMargin}px`}">
                    <qrcode-vue :value="urls[row_index*cols + col_index]" :size="size" level="H" />
                    <div v-if="showURL">
                        {{ urls[row_index*cols + col_index] }}
                    </div>
                </div>
            </div>
        </v-app>
    </div>  
</template>
<script>
import QrcodeVue from 'qrcode.vue'
// https://localhost/sys/public-qr?rows=5&cols=6&size=100&xmargin=50&ymargin=20

export default {
    data() {
        return {
            domain: window.location.origin,
            size: null,
            rows: null,
            cols: null,
            showURL: false,
            xMargin:null,
            yMargin:null,
            urls: [],
        };
    },
    components: {
        QrcodeVue,
    },
    mounted() {
        this.rows = parseInt(this.$route.query.rows) || 4;
        this.cols = parseInt(this.$route.query.cols) || 4;
        this.size = this.$route.query.size || 100;
        this.xMargin = this.$route.query.xmargin || 50;
        this.yMargin = this.$route.query.ymargin || 50;
        this.showURL = this.$route.query.showurl || false;
        // https://localhost/public/temp-dd29999c-fcdc-4585-affa-5153d9415f09?listNameEditMode=write
        for (let x=0; x<this.rows*this.cols; x++) {
            let uuid = crypto.randomUUID();
            let url = this.domain + '/public/' + uuid;
            this.urls.push(url);
        }
    },
};
</script>

<template>
  <div class="root">
    <v-app>
        <v-container class="my-7">
        </v-container>
        <!-- bg-color="deep-green accent-4"
                        dark 
                                :right="right"
            bg-color="indigo-darken-2"
            v-model="tab"
            :centered="centered"
            :prev-icon="prevIcon ? 'mdi-arrow-left-bold-box-outline' : undefined"
            :next-icon="nextIcon ? 'mdi-arrow-right-bold-box-outline' : undefined"
            :vertical="vertical"
            :icons-and-text="icons"

                    -->

        <v-tabs v-model="tab" bg-color="indigo-darken-2" class="elevation-2" fixed-tabs >
            <!-- <v-tabs-slider></v-tabs-slider> -->

            <v-tab href="#friends">
                Friends <br />( {{ counts.friends }} )
            </v-tab>
            <v-tab href="#friend-search">
                Add Friend<br />&nbsp;
                <!-- <CustomIcon size="24" color="#008000" glyph="account-plus-outline" />  -->
            </v-tab>
            <v-tab href="#incoming-requests">
                Incoming Requests <br />( {{ counts.friend_invite_inbound }} )
            </v-tab>
            <v-tab href="#outgoing-requests">
                Outgoing Requests <br />( {{ counts.friend_invite_outbound }} )
            </v-tab>
            <v-tab href="#following">
                Following <br />( {{ counts.following }} )
            </v-tab>
            <v-tab href="#followed-by">
                Followed By <br />( {{ counts.followed_by }} )
            </v-tab>
            <v-tab href="#listening">
                Listening <br />( {{ counts.listening }} )
            </v-tab>
            <v-tab href="#listened-by">
                Listened By <br />( {{ counts.listened_by }} )
            </v-tab>

            <v-tab-item value="friends" >
                <FriendsList ref="tabFriendList" :refreshAllFriendData=refreshAllFriendData />
            </v-tab-item>
            <v-tab-item value="friend-search" >
                <FriendSearch ref="tabFriendSearch" :refreshAllFriendData=refreshAllFriendData />
            </v-tab-item>
            <v-tab-item value="incoming-requests" >
                <FriendInboundRequests ref="tabFriendInputRequests" :refreshAllFriendData=refreshAllFriendData />
            </v-tab-item>
            <v-tab-item value="outgoing-requests" >
                <FriendOutboundRequests ref="tabFriendOutboundRequests" :refreshAllFriendData=refreshAllFriendData />
            </v-tab-item>
            <v-tab-item value="following" >
                <Following ref="tabFollowing" :refreshAllFriendData=refreshAllFriendData />
            </v-tab-item>
            <v-tab-item value="followed-by" >
                <FollowedBy ref="tabFollowedBy" :refreshAllFriendData=refreshAllFriendData />
            </v-tab-item>
            <v-tab-item value="listening" >
                <Listening ref="tabListening" :refreshAllFriendData=refreshAllFriendData />
            </v-tab-item>
            <v-tab-item value="listened-by" >
                <ListenedBy ref="tabListenedBy" :refreshAllFriendData=refreshAllFriendData />
            </v-tab-item>
        </v-tabs>
    </v-app>
  </div>  
</template>
<script>
import api from '/src/utils/api';
// import { mdiAccountPlusOutline } from '@mdi/js';

const Friends = {
    name: 'Friends',
    components: {
        FriendsList: () => import("/src/views/friends/components/FriendsList.vue"),
        FriendSearch: () => import("/src/views/friends/components/FriendSearch.vue"),
        Following: () => import("/src/views/friends/components/Following.vue"),
        FollowedBy: () => import("/src/views/friends/components/FollowedBy.vue"),
        Listening: () => import("/src/views/friends/components/Listening.vue"),
        ListenedBy: () => import("/src/views/friends/components/ListenedBy.vue"),
        FriendInboundRequests: () => import("/src/views/friends/components/FriendInboundRequests.vue"),
        FriendOutboundRequests: () => import("/src/views/friends/components/FriendOutboundRequests.vue"),
    },
    computed: {
        tab: {
            set (tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } })
            },
            get () {
                return this.$route.query.tab
            }
        }
    },
    data() {
        return {
            counts:{},
            // tab: null
        };
    },
    mounted() {
        this.refresh();

        api.postAuthRequired('delete-invite-notifications');
    },
    methods: {
        refresh() {
            console.log("FriendsList refresh");
            api.getCall('get-relation-counts').then(c => {
                this.counts = c[0];
            });
        },
        refreshAllFriendData() {
            this.refresh();
            // The tab refs don't exist until they are viewed, so we do a null check before calling it's function.
            this.$refs.tabFriendList?.refresh();
            this.$refs.tabFriendSearch?.refresh();
            this.$refs.tabFriendInputRequests?.refresh();
            this.$refs.tabFriendOutboundRequests?.refresh();
            this.$refs.tabFollowing?.refresh();
            this.$refs.tabFollowedBy?.refresh();
        },
    },
};

export default Friends;
</script>

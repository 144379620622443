<template>
<div>
    
</div>
</template>
<script>
import auth from "/src/utils/auth";
import api from '/src/utils/api';

export default {
    name: "Callback",
    mounted() {
        var previousRoute = this.$route.query.previousRoute;

        this.$nextTick(() => {
            auth.setAccessToken().then(() => {
                let deviceToken = localStorage.getItem('deviceToken') || '';
                api.postAuthRequired('register-device', {deviceToken});
            });
            auth.setIdToken();


            // Calling getUser will cause the channel name to be put into a localstorage variable.
            api.getUser().then((user) => {
                // if (!user[0].channelName)
                //   window.location.href = "/sys/profile";
                // else
                //   window.location.href = "/" + user[0].channelName;

                api.syncIDPUser().then((idp_user) => {
                    // Return user to the page they were viewing before logging in.
                    window.location.href = previousRoute;
                });
            });
        });
    },
    methods: {
    }
};
</script>

<template>
    <v-app>
        <SiteHeader v-if="$route.meta.showHeader != false" />
        <router-view />
    </v-app>
</template>

<script>
import api from '/src/utils/api';

export default {
    name: 'App',
    components: {
        SiteHeader: () => import("./components/SiteHeader.vue")
    },
    data() {
        return {
            channelName: '',
        };
    },
    mounted() {
        api.logVisit()
    }
};
</script>
